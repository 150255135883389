<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Login
      <router-link slot="right" to="/signup" class="button">
        signup
      </router-link>
    </hero-bar>


 

    <section class="section is-main-section">



<!-- Signup form -->
<div id="userfront-aamnok"></div>

    
<!-- Login form -->
<div id="userfront-oaknbn"></div>


  <hr>

      <div>
        <form @submit.prevent="login">
          <h2>Login</h2>
          <input
            type="email"
            placeholder="Email address..."
            v-model="email"
          />
          <input
            type="password"
            placeholder="password..."
            v-model="password"
          />
          <button type="submit">
             Login
          </button>
        </form>
      </div>




      REGISTER!


      <div>
  <form @submit.prevent="register">
    <h2>Register</h2>
    <input
      type="email"
      placeholder="Email address..."
      v-model="email"
    />
    <input
      type="password"
      placeholder="password..."
      v-model="password"
    />
    <button type="submit">Register</button>
  </form>
</div>




      <tiles>
        <profile-update-form class="tile is-child" />
        <card-component title="Profile" icon="account" class="tile is-child">
          <user-avatar class="image has-max-width is-aligned-center" />
          <hr />
          <b-field label="Name">
            <b-input :value="userName" custom-class="is-static" readonly />
          </b-field>
          <hr />
          <b-field label="E-mail">
            <b-input :value="userEmail" custom-class="is-static" readonly />
          </b-field>
        </card-component>
      </tiles>
      <password-update-form />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import ProfileUpdateForm from '@/components/ProfileUpdateForm'
import PasswordUpdateForm from '@/components/PasswordUpdateForm'
import Tiles from '@/components/Tiles'
import UserAvatar from '@/components/UserAvatar'
import firebase from "firebase";
export default {
  name: 'Profile',
  components: {
    UserAvatar,
    Tiles,
    PasswordUpdateForm,
    ProfileUpdateForm,
    HeroBar,
    TitleBar,
    CardComponent
  },
   mounted: function () {
     alert('mounted')
    Userfront.render()
  },
  methods: {
  register() {
    firebase
      .auth()
      .createUserWithEmailAndPassword(this.email, this.password)
      then(() => {
        alert('Successfully registered! Please login.');
        this.$router.push('/?logged=yessss');
      })
      .catch(error => {
        alert(error.message);
      });
  },
},
  computed: {
    titleStack () {
      return ['AirJson', 'Profile']
    },
    ...mapState(['userName', 'userEmail'])
  }
}
</script>
